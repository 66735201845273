import React, { useState } from "react"
import styled from "styled-components"

import { Section, Container } from "../global"




const Features = () => {

  const [emailVal, setEmailVal] = useState('');

  const [nameVal, setNameVal] = useState('');

  const url = "https://clfw2yanvi.execute-api.eu-west-2.amazonaws.com/dev/link";

  const handleSubmit = event => {
    event.preventDefault()
    // console.log(event.target);

    
    var data = { 'name': nameVal  ,'email': emailVal};
    console.log(data);

    fetch(url, {
      mode: 'cors',
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
   
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

      


  }


  const handleNameChange = event => {
    //event.preventDefault()

    setNameVal(event.target.value);


  }


  const handleEmailChange = event => {
    //event.preventDefault()

    setEmailVal(event.target.value);


  }

  return (<Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <FeatureText>
        Unfortunately you have caught us before we are ready. <br />
        We are working rapidly to develop Vlutor so sign up here to get early access.
          </FeatureText>

      <FeatureForm onSubmit={handleSubmit}>
      <FeatureInput placeholder="Your name" onChange={handleNameChange} value={nameVal} />
        <FeatureInput placeholder="Your email" onChange={handleEmailChange} value={emailVal} type="email"/>
        <FeatureButton>Get Early access </FeatureButton>
      </FeatureForm>

      <SectionTitle>Fun Learning Experience </SectionTitle>

      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Visual</FeatureTitle>
          <FeatureText>
            Project items live into your room using Augmented Reality.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Virtual Tutor</FeatureTitle>
          <FeatureText>
            Friendly voice as part of your learning journey using Artificial Intelligence.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Security</FeatureTitle>
          <FeatureText>
            Your data is always safe with us as we use the latest security
            protocols.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Personalised</FeatureTitle>
          <FeatureText>
            Automatically customised learning experience to suit you.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Shopping</FeatureTitle>
          <FeatureText>Purchase tokens to unlock different levels.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Rewards</FeatureTitle>
          <FeatureText>
            Get medals for playing consistently and improving performance.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>);
}

export default Features

const StyledContainer = styled(Container)``


const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`
const FeatureForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: center;
`

const FeatureInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 30%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const FeatureButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.tertiary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`